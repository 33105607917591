import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'base',
    component: () => import('../Base.vue'),
    meta: {
      requires_auth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/shared-pages/Dashboard.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/therapist-detail/:id',
        name: 'Terapeuta',
        component: () => import('../views/role-clinic-pages/therapist-details/TherapistDetails.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/schedules',
        name: 'Agendamentos',
        component: () => import('../views/role-clinic-pages/schedule/SchedulesPage.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/schedule/:id',
        name: 'Detalhes Agendamento',
        component: () => import('../views/role-clinic-pages/schedule/ScheduleDetails.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/therapists',
        name: 'Terapeutas',
        component: () => import('../views/role-clinic-pages/TherapistsPage.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/patients',
        name: 'Pacientes',
        component: () => import('../views/role-clinic-pages/patient/PatientsPage.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/patient/:id',
        name: 'Detalhes Paciente',
        component: () => import('../views/role-clinic-pages/patient/PatientDetails.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/clinics',
        name: 'Clinicas',
        component: () => import('../views/role-adm-pages/ClinicsPage.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/reports',
        name: 'Relatórios',
        component: () => import('../views/role-clinic-pages/report/ReportPage.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/notice-board',
        name: 'Mural de Avisos',
        component: () => import('../views/role-clinic-pages/notice-board/NoticeBoardPage.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/configs',
        name: 'Configurações',
        component: () => import('../views/shared-pages/ConfigsPage.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/patient-evolutions',
        name: 'Evolução do Paciente',
        component: () => import('../views/patient-pages/PatientEvolutions.vue'),
        meta: {
          requires_auth: true
        }
      },
      {
        path: '/chat',
        name: 'Chat',
        component: () => import('../views/shared-pages/Chat.vue'),
        meta: {
          requires_auth: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  //
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/errors/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
